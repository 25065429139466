import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { DialogService } from '@primeng';
import { SharedRootModule } from '@app/shared';
import { CustomizeDashboardComponent } from './components/customize-dashboard/customize-dashboard.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomizeDashboardService } from './components/customize-dashboard/customize-dashboard-service';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '@app/core';
import { TooltipModule } from 'primeng/tooltip';
import { NotificationsComponent } from '@app/shared/components/notifications/notifications.component';
import { RealTimeNotificationComponent } from '@app/shared/components/real-time-notification/real-time-notification.component';
import { AccountSettingComponent } from '@app/shared/components/account-setting/account-setting.component';
import { ImageDialogComponent } from '@app/shared/components/account-setting/dialog/image.dialog.component';
import { NgxPermissionsService, NgxPermissionsModule } from 'ngx-permissions';
import { AuthenticationService } from '@app/core';
import { Store } from '@ngrx/store';
import { ExportService } from './services/export.service';
import { HelperService } from './services/helper.service';
import { ReportingDefaultComponent } from './components/reportingDefaultComponent';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { WidgetLayoutComponent } from '../widgets/widget-layout.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CommentListModalComponent } from './components/comment-list-modal/comment-list-modal.component';
import { TreeReportComponent } from '../voc/voc-report/tree-report/tree-report.component';
import { WidgetSpinnerComponent } from '../widgets/widget-spinner.component';
import { ResourceDownloadDirective } from './directives/resource-download.directive';
import { ImageCropperModule } from '@app/shared/components/account-setting/dialog/image-cropper/image-cropper.module';
import { DynamicTopicComponent } from '../voc/voc-report/dynamic-topic/dynamic-topic.component';
// import { SurveyListService } from './components/survey-list/survey-list.service';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
// import { ContactStatisticsService } from '../reporting/contact-statistics/contact-statistics.service';
// import { VocDashboardService } from '../voc/voc-dashboard.service';
// import { NotificationsComponent } from '@app/shared/components/notifications/notifications.component';
@NgModule({
  imports: [
    ProgressSpinnerModule,
    CommonModule,
    ImageCropperModule,
    RouterModule,
    FormsModule,
    TooltipModule,
    DragDropModule,
    SharedRootModule,
    InfiniteScrollModule,
    NgxPermissionsModule.forRoot(),
  ],
  declarations: [
    WidgetLayoutComponent,
    WidgetSpinnerComponent,
    SidebarComponent,
    HeaderComponent,
    ReportingDefaultComponent,
    ConfirmationModalComponent,
    CommentListModalComponent,
    SurveyListComponent,
    //  ImageDialogComponent,
    // NotificationsComponent,
    CustomizeDashboardComponent,
    TreeReportComponent,
    DynamicTopicComponent,
    ResourceDownloadDirective,
  ],
  providers: [
    DialogService,
    HelperService,
    // SurveyListService,
    // ContactStatisticsService,
    ReportingDefaultComponent,
    //  CustomizeDashboardService
    // ,VocDashboardService
  ],
  exports: [
    WidgetLayoutComponent,
    WidgetSpinnerComponent,
    RouterModule,
    SharedRootModule,
    SidebarComponent,
    SurveyListComponent,
    HeaderComponent,
    NgxPermissionsModule,
    TooltipModule,
    TreeReportComponent,
    DynamicTopicComponent,
    ResourceDownloadDirective,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DialogService,
        HelperService,
        // SurveyListService,
        // ContactStatisticsService,
        // VocDashboardService
      ],
    };
  }
  constructor(
    private permissionsService: NgxPermissionsService,
    private authService: AuthenticationService,
    private store: Store<{ EventReducer: any }>
  ) {
    this.store.subscribe((data) => {
      if (this.authService.authData && this.authService.authData.Features) {
        const permissions = this.authService.authData.Features.map((x: any) => x.Name);
        this.permissionsService.loadPermissions(permissions);
      }
    });
  }
}
